import React, { useEffect, useRef, useState } from "react";
import "./eventcluster.scss";
import { Button, Col, Row, Modal } from "react-bootstrap";
import { useMapContext } from "../../context/Mapcontext";
import { useMap } from "react-map-gl/maplibre";
import { eventUrl } from "../../../urls/urls";
import maplibregl from "maplibre-gl";
import { Layer, Source } from "react-map-gl";
import rightarrow from "../../../assets/images/right.png";
import leftarrow from "../../../assets/images/left.png";
import calendar from "../../../assets/images/calendar.png";
import eventpin from "../../../assets/images/event-marker.png";

const Eventcluster = () => {
  const [events, setEvents] = useState([]);
  const { id, setIdValue, clusterName, setClusterNameValue } = useMapContext();
  const [selectedClustername, setSelectedClustername] = useState(null);
  const [selectedSubcluster, setSelectedSubcluster] = useState(null);
  const [select, setSelect] = useState(null);
  const [show, setShow] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const url = eventUrl;
  const { current: map } = useMap();

  const daysOfWeek = ["Sept 21st", "Sept 22nd", "Sept 23rd"];

  // const daysOfWeek = [
  //   "Sunday",
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  // ];

  const lineStyle = {
    id: "route",
    type: "line",
    source: "route",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#E37547",
      "line-width": 8,
    },
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            // setEvents(data.data.results);
            const filteredEvents = data.data.results.filter(
              (event) => event.isActive && event.isSpecial
            );
            setEvents(filteredEvents);
          } else {
            console.log("Failed to fetch events:", data.message);
          }
        } else {
          console.log("Failed to fetch events:", response.statusText);
        }
      } catch (error) {
        console.error("Failed to fetch events", error);
      }
    };

    fetchEvents();
  }, [url]);

  useEffect(() => {
    if (!map || !selectedClustername) {
      setSelect(null); // Reset selected line if there's no map or no selected cluster
      return;
    }

    const selectedEvent = events.find((event) => event._id === id);

    if (selectedEvent && selectedEvent.line) {
      const lines = JSON.parse(selectedEvent.line);
      let selectedLine;

      if (selectedSubcluster) {
        selectedLine = lines?.features?.find(
          (feature) =>
            feature.properties.clustername === selectedClustername &&
            feature.properties.subcluster === selectedSubcluster
        );
      } else {
        selectedLine = lines?.features?.find(
          (feature) => feature.properties.clustername === selectedClustername
        );
      }

      if (selectedLine) {
        setSelect(selectedLine);

        const bounds = new maplibregl.LngLatBounds();
        let isValid = true;

        const extendBounds = (coords) => {
          if (Array.isArray(coords[0])) {
            coords.forEach((coord) => {
              if (
                Array.isArray(coord) &&
                !isNaN(coord[0]) &&
                !isNaN(coord[1])
              ) {
                bounds.extend(coord);
              } else {
                console.error("Invalid coordinates: ", coord);
                isValid = false;
              }
            });
          } else if (!isNaN(coords[0]) && !isNaN(coords[1])) {
            bounds.extend(coords);
          } else {
            console.error("Invalid coordinates: ", coords);
            isValid = false;
          }
        };

        if (selectedLine.geometry.type === "LineString") {
          extendBounds(selectedLine.geometry.coordinates);
        } else if (selectedLine.geometry.type === "MultiLineString") {
          selectedLine.geometry.coordinates.forEach((lineCoords) => {
            extendBounds(lineCoords);
          });
        } else {
          console.error(
            "Unexpected geometry type: ",
            selectedLine.geometry.type
          );
          isValid = false;
        }

        if (isValid) {
          map.fitBounds(bounds, { padding: 50 });
        } else {
          console.error("Could not fit bounds due to invalid coordinates.");
        }
      }
    }
  }, [map, selectedClustername, selectedSubcluster, events, id]);

  useEffect(() => {
    if (map && id && selectedClustername && !selectedSubcluster) {
      const selectedEvent = events.find((event) => event._id === id);

      if (selectedEvent && selectedEvent.points) {
        try {
          const points = JSON.parse(selectedEvent.points);
          const selectedPoints = points?.features?.filter(
            (feature) => feature.properties.clustername === selectedClustername
          );

          if (selectedPoints && selectedPoints.length > 0) {
            // Filter out points without valid coordinates
            const validPoints = selectedPoints.filter(
              (feature) =>
                feature?.geometry?.coordinates &&
                Array.isArray(feature.geometry.coordinates) &&
                feature.geometry.coordinates.length === 2 // Ensure there are two elements in coordinates
            );

            if (validPoints.length > 0) {
              const coordinates = validPoints.map(
                (feature) => feature.geometry.coordinates
              );

              const lats = coordinates.map((coord) => coord[1]); // coord[1] = latitude
              const lngs = coordinates.map((coord) => coord[0]); // coord[0] = longitude

              const bounds = [
                [Math.min(...lngs), Math.min(...lats)],
                [Math.max(...lngs), Math.max(...lats)],
              ];

              map.fitBounds(bounds, {
                padding: 50,
              });
            } else {
              console.warn(
                "No valid coordinates found for the selected cluster."
              );
            }
          }
        } catch (error) {
          console.error("Error parsing points data", error);
        }
      }
    }
  }, [map, id, events, selectedClustername, selectedSubcluster]);

  const handleClusterClick = (clustername) => {
    setSelectedClustername((prevClustername) =>
      prevClustername === clustername ? null : clustername
    );

    setClusterNameValue((prevClustername) =>
      prevClustername === clustername ? null : clustername
    );

    setSelectedSubcluster(null); // Reset subcluster selection
  };

  const handleSubclusterClick = (subcluster) => {
    setSelectedSubcluster((prevSubcluster) =>
      prevSubcluster === subcluster ? null : subcluster
    );
  };

  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -150, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 150, behavior: "smooth" });
    }
  };

  const handleMarkerClick = (point) => {
    setSelectedPoint(point);
    setShow(true);
  };
  const handleClose = () => {
    setSelectedPoint(null);
    setShow(false);
  };

  const renderedClusterNames = new Set();
  const renderedSubclusterNames = new Set();

  return (
    <>
      <div className="cluster-box-container" style={{ display: "flex" }}>
        <div className="cluster-box" ref={scrollContainerRef}>
          {events
            .filter((event) => event._id === id)
            .map((event) => {
              let features = [];

              if (event.line) {
                try {
                  const lines = JSON.parse(event.line);
                  if (lines && lines.features) {
                    features = features.concat(lines.features);
                  }
                } catch (error) {
                  console.error("Error parsing JSON:", error);
                }
              } else {
                console.error("Event line is undefined:", event);
              }

              if (event.points) {
                try {
                  const points = JSON.parse(event.points);
                  if (points && points.features) {
                    features = features.concat(points.features);
                  }
                } catch (error) {
                  console.error("Error parsing JSON:", error);
                }
              } else {
                console.error("Event points are undefined:", event);
              }
              // Separate clusterNames into daysOfWeek and others
              const dayClusterNames = [];
              const otherClusterNames = [];

              features.forEach((feature) => {
                const clusterName = feature.properties?.clustername;
                if (clusterName && !renderedClusterNames.has(clusterName)) {
                  if (daysOfWeek.includes(clusterName)) {
                    dayClusterNames.push(clusterName);
                  } else {
                    otherClusterNames.push(clusterName);
                  }
                }
              });

              // Sort dayClusterNames according to the correct order of days
              const sortedDayClusterNames = dayClusterNames.sort(
                (a, b) => daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b)
              );

              // Combine sorted day cluster names with other cluster names
              const combinedClusterNames = [
                ...sortedDayClusterNames,
                ...otherClusterNames,
              ];

              return combinedClusterNames.map((clusterName, index) => {
                if (clusterName && !renderedClusterNames.has(clusterName)) {
                  renderedClusterNames.add(clusterName);
                  return (
                    <div key={index} style={{ padding: "8px" }}>
                      <Button
                        className={
                          selectedClustername === clusterName ? "selected" : ""
                        }
                        onClick={() => handleClusterClick(clusterName)}
                      >
                        <span>{clusterName}</span>
                      </Button>
                    </div>
                  );
                }
                return null; // Do not render anything if cluster name has already been rendered
                // return features.map((feature, index) => {
                //   const clusterName = feature.properties?.clustername;
                //   if (clusterName && !renderedClusterNames.has(clusterName)) {
                //     renderedClusterNames.add(clusterName);
                //     return (
                //       <div key={index} style={{ padding: "8px" }}>
                //         <Button
                //           className={
                //             selectedClustername === clusterName ? "selected" : ""
                //           }
                //           style={{
                //             width: "12vw",
                //             borderRadius: "30px",
                //           }}
                //           onClick={() => handleClusterClick(clusterName)}
                //         >
                //           <span>{clusterName}</span>
                //         </Button>
                //       </div>
                //     );
                //   }
                //   return null; // Do not render anything if cluster name has already been rendered
              });
            })}
          {select && (
            <Source type="geojson" data={select}>
              <Layer {...lineStyle} />
            </Source>
          )}
        </div>
        <div className="scroll-left" onClick={scrollLeft}>
          <img
            src={leftarrow}
            alt="left"
            style={{ height: "20px", width: "20px" }}
          />
        </div>
        <div className="scroll-right" onClick={scrollRight}>
          <img
            src={rightarrow}
            alt="right"
            style={{ height: "20px", width: "20px" }}
          />
        </div>
      </div>

      {/* when coordinates are not Present */}
      <div className="subcluster-box">
        {clusterName &&
          events
            .filter((event) => event._id === id)
            .map((event) => {
              if (event.points) {
                let points;
                try {
                  points = JSON.parse(event.points);
                } catch (error) {
                  console.error("Failed to parse points data", error);
                  return [];
                }

                if (points && points.features) {
                  return points.features
                    .filter(
                      (point) =>
                        point.properties &&
                        point.properties.clustername === clusterName
                    )
                    .map((point, index) => {
                      const coordinates = point?.geometry?.coordinates;

                      // Handle case when coordinates are not available
                      if (!coordinates || coordinates.length < 2) {
                        return (
                          <div style={{ padding: "8px" }}>
                            <Button
                              key={index}
                              style={{
                                width: "13vw",
                                borderRadius: "20px",
                                marginLeft: "10px",
                              }}
                              onClick={() => handleMarkerClick(point)}
                            >
                              <span> {point.properties.name}</span>
                            </Button>
                          </div>
                        );
                      }
                    });
                }
              }
            })}
      </div>

      <div className="subcluster-box">
        {selectedClustername &&
          events
            .filter((event) => event._id === id)
            .flatMap((event) => {
              let features = [];

              if (event.line) {
                try {
                  const lines = JSON.parse(event.line);
                  if (lines && lines.features) {
                    features = features.concat(lines.features);
                  }
                } catch (error) {
                  console.error("Error parsing JSON:", error);
                }
              }

              if (event.points) {
                try {
                  const points = JSON.parse(event.points);
                  if (points && points.features) {
                    features = features.concat(points.features);
                  }
                } catch (error) {
                  console.error("Error parsing JSON:", error);
                }
              }

              return features;
            })
            .filter(
              (feature) =>
                feature.properties?.clustername === selectedClustername
            )
            .map((feature, index) => {
              const subClusterName = feature.properties?.subcluster;
              if (
                subClusterName &&
                !renderedSubclusterNames.has(subClusterName)
              ) {
                renderedSubclusterNames.add(subClusterName);
                return (
                  <Button
                    key={index}
                    style={{
                      width: "8vw",
                      borderRadius: "20px",
                      marginLeft: "10px",
                    }}
                    onClick={() => handleSubclusterClick(subClusterName)}
                  >
                    <span>{subClusterName}</span>
                  </Button>
                );
              }
              return null;
            })}
      </div>

      {/* render popup modal for info of selected point/marker */}
      {selectedPoint && (
        <Modal show={show} onHide={handleClose} size="md">
          <Modal.Header closeButton>
            <Modal.Title>{selectedPoint.properties.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {selectedPoint.properties.image ? (
                <img
                  style={{ objectFit: "cover" }}
                  src={selectedPoint.properties.image}
                  alt="event"
                />
              ) : (
                ""
              )}
            </div>
            <p style={{ marginTop: "1rem" }}>{selectedPoint.properties.info}</p>

            <div className="location-date">
              {selectedPoint.properties.date ? (
                <Row>
                  <Col md={1}>
                    <img src={calendar} alt="calendar" />
                  </Col>
                  <Col md={11}>
                    <span>{selectedPoint.properties.date}</span>
                  </Col>
                </Row>
              ) : (
                ""
              )}

              {selectedPoint.properties.location ? (
                <Row>
                  <Col md={1}>
                    <img
                      style={{ height: "20px", width: "20px" }}
                      src={eventpin}
                      alt="eventpin"
                    />
                  </Col>
                  <Col md={11}>
                    <span
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                      }}
                    >
                      {selectedPoint.properties.location}
                    </span>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </div>

            <div className="btn-section">
              {selectedPoint.properties.callToAction ? (
                <Button href={selectedPoint.properties.link}>
                  <span>{selectedPoint.properties.callToAction}</span>
                </Button>
              ) : (
                ""
              )}

              <Button>
                <span style={{ textTransform: "uppercase" }}>share</span>
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Eventcluster;
